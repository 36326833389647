body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

input {
  /*The !important is necessary to overwrite Material UI and Firebase inputs*/
  font-family: 'Source Sans Pro' !important;
}

.loading-circle {
  border: 16px solid darkgrey; /* Grey track */
  border-top: 16px solid #01717a; /* Dark teal */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
